import * as React from 'react'
import WrkitRedirectSsoLogin from '@/templates/WrkitRedirectSsoLogin'

function WrkitRedirectSso({ssoOnly}) {

	return <WrkitRedirectSsoLogin ssoOnly={ssoOnly} />
}

WrkitRedirectSsoLogin.defaultProps = {
	ssoOnly: (SSO_ONLY==='1')
}

export default WrkitRedirectSso